(() => {
  const pluginName = 'OutlineNav';

  const defaults = {
    headingSelector: 'h2'
  };

  class OutlineNav {
    constructor(container, options) {
      this.container = container;
      this.options = Object.assign({}, defaults, options);
      this.headingSelector = this.options.headingSelector;
      
      this.headingElements = Array.from(document.querySelectorAll(this.headingSelector));
      
      this.generateHeadingIdentifiers();
      this.buildNavigation();
    }

    generateHeadingIdentifiers() {
      this.headingElements.forEach(heading => {
        if (heading.getAttribute('id')) return;

        // Generate sanitized ID without whitespace or special symbols https://regex101.com/r/5JWswv/1
        const generatedId = heading.innerText.trim().toLowerCase().replace(/#|\s/g, '-');

        heading.setAttribute('id', generatedId);
      });
    }

    buildNavigation() {
      const listItems = this.headingElements.map(heading => {
        const anchor = document.createElement('a');
        anchor.setAttribute('href', `#${heading.getAttribute('id')}`);
        anchor.innerText = heading.innerText.trim();
        
        const listItem = document.createElement('li');
        listItem.append(anchor);

        return listItem;
      });

      this.container.append(...listItems);
    }
  }

  if (window.jQuery) {
    $.fn[pluginName] = function(options) {
      return this.each(function() {
        if (!$.data(this, pluginName)) {
          $.data(this, pluginName, new OutlineNav(this, options));
        }
      });
    };
  }
})();
