(() => {
  const nav = document.querySelector('.ContextualNav');

  if (nav === null) return;

  const trigger = document.querySelector('.js-nav-trigger');
  const dropdownMenus = Array.from(document.querySelectorAll('.ContextualNav ul:not(:only-child)'));

  const expand = el => el.classList.toggle('is-expanded');

  const closeDropdown = () => {
    const openDropdown = document.querySelector('.ContextualNav-content li.is-expanded');
    if (openDropdown) openDropdown.classList.remove('is-expanded');
  };

  const outsideClick = (event) => {
    if (!nav.contains(event.target)) {
      closeDropdown();
      document.removeEventListener('click', outsideClick);
    }
  };

  dropdownMenus.map(menu => {
    const trigger = menu.parentElement;
    trigger.addEventListener('click', () => {
      if (!trigger.classList.contains('is-expanded')) closeDropdown();
      expand(trigger);
      document.addEventListener('click', outsideClick);
    });
  });

  trigger.addEventListener('click', () => expand(nav));
})();
