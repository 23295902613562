function getParameterByName(name, url) {
  let currentURL = url;
  if (!currentURL) currentURL = window.location.href;
  let cleanedName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + cleanedName + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(currentURL);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const queryParameterValue = getParameterByName("utm_term");
if (queryParameterValue) {
  let toBeReplaced = document.querySelector(".js-to-be-replaced");
  if (toBeReplaced) {
    toBeReplaced.innerText = queryParameterValue;
  }
}
