import $ from 'jquery';

(() => {
  if (!$.support.cssProperty('object-fit')) {
    $('.Section-bg img, img.-contain').each(function each() {
      var $this = $(this);
      const $container = $this.parent();
      const imgUrl = $this.prop('src');
      const imgClasses = $this.attr('class');
      if (imgUrl) {
        if ($this.hasClass('-contain')) {
          $container
            .css('backgroundImage', 'url(' + imgUrl + ')')
            .addClass('compat-object-contain');
        } else {
          $container
            .css('backgroundImage', 'url(' + imgUrl + ')')
            .addClass('compat-object-fit')
            .addClass(imgClasses);
          // fix for ie's missing background color behind the banner image
          if ($container.hasClass('-bg-black')) {
            $container.parent().addClass('-tint-black');
          }
        }
      }
    });
  }
})();
