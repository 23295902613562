import $ from 'jquery';

// litebox fix
const liteboxImageLinks = Array.from(document.querySelectorAll('a[href*=".jpg"], a[href*=".jpeg"], a[href*=".png"], a[href*=".webp"], .litebox-button'));
liteboxImageLinks.forEach((link) => link.classList.contains('litebox-button') ? link.classList.add('litebox') : link.classList.add('litebox', '-l-pen'));


// required class for forms
$('input[required], select[required], textarea[required]').prevAll('label').addClass('required');

// detect ie
(function detectIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');

  if (msie > 0 || trident > 0) {
    document.body.classList.add('t-ie');
  }
})();

// add aria-labels to all kinds of pagination links
const setAriaLabelsOnPager = () => {
  let activePageLink = document.querySelector('.TK-Pager-Links a.is-active');
  let prevPageLink = document.querySelector('.TK-Pager-Prev a');
  let nextPageLink = document.querySelector('.TK-Pager-Next a');
  if(activePageLink) activePageLink.setAttribute('aria-label', 'Selected page');
  if(prevPageLink) prevPageLink.setAttribute('aria-label', 'Previous');
  if(nextPageLink) nextPageLink.setAttribute('aria-label', 'Next');
}
window.addEventListener("load", (e) => {
  setTimeout(setAriaLabelsOnPager, 500);
});
