import SocialShare from '@progress-wad/social-share';
import { interactive } from './interactive.js';

interactive().then(() => {
  const elements = Array.from(document.querySelectorAll('#js-social-share a, .js-social-share a'));
  elements.forEach(element => new SocialShare({
    element,
    media: element.dataset.network
  }));
});
