(() => {
  function listenForClicks(e) {
    e.addEventListener("click", function(e) {
      let el = e.currentTarget;
      let newClass = el.getAttribute("class").split("js-tglslf-")[1].split(" ")[0];
      el.classList.toggle(newClass);
    })
  }
  document.querySelectorAll("[class*='js-tglslf-']").forEach(function(e) {
    listenForClicks(e)
  });
})();
