import $ from 'jquery';

/**
 * Fragments (when a .js-fragment element comes into view it gets a new classname - is-intoview)
 *
 * Add modifier .js-fragment--once to execute animation only on the first appearance
 * Add data-offset="200" to the .js-fragment element, to trigger the animation, after offset of 200px from top/bottom viewport
 * Add a specific appearing animation to the element using classes from _fragments.styl
 */

(() => {
  const $fragments = $('.js-fragment');
  let topOffset = 0; // set data-offset="xxx" on the js-fragment element to control each one
  let scrolling = false;

  if (!$fragments.length) return;

  function isIntoView($fragment) {
    const fragmentTop = $fragment.offset().top;
    const fragmentBottom = fragmentTop + $fragment.outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();
    topOffset = $fragment.data('offset') || topOffset;
    return (fragmentBottom - topOffset) > viewportTop && fragmentTop < (viewportBottom - topOffset);
  }

  function checkFragments() {
    if (!scrolling) {
      return;
    }
    scrolling = false;

    $fragments.each((i, fragment) => {
      const $fragment = $(fragment);
      if (isIntoView($fragment)) $fragment.addClass('is-intoview');
      else if (!$fragment.hasClass('js-fragment--once')) $fragment.removeClass('is-intoview');
    });
  }

  function throttleScroll() {
    scrolling = true;
  }

  $(window).on('scroll', throttleScroll);
  window.setInterval(checkFragments, 100);
  scrolling = true;
  checkFragments();
})();
