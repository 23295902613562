
(() => {
  const forms = document.querySelectorAll('.js-floating-labels');

  forms.forEach((form) => {
    const allFields = form.querySelectorAll("input:not(.-dn):not([type=hidden]):not([type=checkbox]):not([type=radio]), select, textarea")

    allFields.forEach((field) => {
      const wrap = field.closest('.sf-fieldWrp, .form-group');
      const error = wrap.querySelector('[data-sf-role="error-message"]');

      const isEmpty = (el) => {
        return (el.tagName.toLowerCase() === 'select') ? false : !(el.value.length)
      };

      field.addEventListener('focus', () => {
        wrap.classList.remove('is-empty');
        wrap.classList.add('has-focus');
      }, true);

      field.addEventListener('blur', () => {
        wrap.classList.remove('has-focus');
        if (isEmpty(field))
          wrap.classList.add('is-empty');
      }, true);

      field.addEventListener('change', () => {
        (isEmpty(field))
          ? wrap.classList.add('is-empty')
          : wrap.classList.remove('is-empty');
      }, true);

      const errorHandle = (error) => {
        if (error.classList.contains('sfError') && error.style.display === 'block') {
          wrap.classList.add('has-error');
        }
        else {
          wrap.classList.remove('has-error');
        }
      };

      // Callback function to execute when mutations are observed
      const callback = (mutations) => {
        for (const mutation of mutations) {
          if (mutation.type === 'attributes')
            errorHandle(error);
        }
      };

      const observer = new MutationObserver(callback);
      observer.observe(error, { attributes: true, childList: false, subtree: false });

      const initField = (field) => {
        // Detect browser autofill and mark empty fields via event dispatch
        const event = new Event('change');
        field.dispatchEvent(event);
      }

      initField(field);
    });
  });
})();
