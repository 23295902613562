import $ from 'jquery';

const pluginName = 'tabs';

const defaults = {
  wrapperSelector: '.ipswitch-tabs', // jquery selector for the wrapper of all tabs
  activeTab: 1, // which tab is active by default
  scrollToTabs: true, // whether to scroll page to the tab-area on click of any tab-link
  automated: false, // auto rotation of tabs (example usage: testimonials)
  automatedTimer: 5000, // auto rotation delay between each tab
  automatedFreeze: true, // freeze auto rotation when mouseover a tab
  setAnchors: true, // set hash, on click of tabs navigation (you can disable for cases like testimonials)
  animateHeight: false, // dynamically animate the height of the wrapper while switching between tabs
  enableSwipe: true,

  // how to treat the event
  preventDefault: true,
  stopPropagation: false
};

function Plugin(element, options) {
  this.$element = $(element);
  this.tabLinks = this.$element.find('a');
  this.options = $.extend({}, defaults, options);
  this.innerWrap = $(this.options.wrapperSelector);
  this.tabs = this.innerWrap.children();
  this.tabsCount = this.tabs.length;
  this.activeTab = parseInt(this.options.activeTab, 10) || 1;
  this.automation = false;

  // change tabs on click
  const $this = this;
  this.tabLinks.on('click', e => {
    if (typeof $this.automation !== 'undefined' && $this.automation !== false) {
      $this._AutomationPause();
    }
    $this.show(e, null);
  });

  // check URL hash
  this._hashcheck();

  // execute initial tab to be shown
  if (this.activeTab && this.activeTab > 0) {
    this.show(null, this.activeTab - 1);
  }

  // automate rotation of tabs if needed
  if (this.options.automated) {
    this._autoRotate();
  }

  // swipe support
  if (this.options.enableSwipe && typeof window.ontouchstart !== 'undefined') {
    this.$element.closest('.Section').css('overflow-x', 'hidden');
    this.innerWrap[0].addEventListener('touchstart', $.proxy(this._touchstart, this));
    this.innerWrap[0].addEventListener('touchend', $.proxy(this._touchend, this));
    this.innerWrap[0].addEventListener('touchcancel', $.proxy(this._touchend, this));
    this.innerWrap[0].addEventListener('touchmove', $.proxy(this._touchmove, this));
  }

  let resizing = false;
  $(window).on('resize', () => {
    resizing = true;
  });

  window.setInterval(() => {
    if (resizing) {
      resizing = false;
      $this.innerWrap.height($this.tabs.filter('.is-active').height());
    }
  }, 800);
}

Plugin.prototype = {
  _translateX(x) {
    this.innerWrap[0].style.transform = `translateX(${-x}%)`;
    // stop automation once swiping has occured
    this._AutomationPause();
  },
  _resetPosition() {
    this._translateX(0);
  },
  _AutomationPause() {
    if (this.automation) {
      window.clearInterval(this.automation);
      this.automation = false;
    }
  },
  _AutomationPlay() {
    if (!this.automation) {
      this._initAutomation();
    }
  },
  _initAutomation() {
    if (this.automation) {
      window.clearInterval(this.automation);
    }
    const $plugin = this;
    this.automation = window.setInterval(() => {
      $plugin._showNextTab();
    }, this.options.automatedTimer);
  },
  _showNextTab() {
    const selected = this.tabLinks.filter('.is-active').eq(0).index();
    const next = (selected === this.tabLinks.length - 1) ? 0 : selected + 1;
    this.show(null, next);
  },
  _showPrevTab() {
    const selected = this.tabLinks.filter('.is-active').eq(0).index();
    const prev = (selected === 0) ? this.tabLinks.length - 1 : selected - 1;
    this.show(null, prev);
  },
  _autoRotate() {
    // start auto animation
    this._initAutomation();

    const $plugin = this;

    // set mouse hover events if needed
    if (this.options.automatedFreeze) {
      const $section = this.innerWrap.closest('.Section');
      const $hoverArea = $section.length ? $section : this.innerWrap;

      $hoverArea.on('mouseenter', () => {
        $plugin._AutomationPause();
      });
      $hoverArea.on('mouseleave', () => {
        $plugin._AutomationPlay();
      });
    }

    // pause automation when plugin is not into view
    let scrolling = false;
    function scrollHandler() {
      const tabsData = $plugin._calcPosition();
      const tabsTop = tabsData[0];
      const tabsBottom = tabsData[1];
      const vTop = $(window).scrollTop();
      const vBottom = vTop + $(window).height();
      if (tabsBottom > vTop && tabsTop < vBottom) {
        // into view
        $plugin._AutomationPlay();
      }
      else {
        // not into view
        $plugin._AutomationPause();
      }
    }

    $(window).on('scroll', () => {
      scrolling = true;
    });

    window.setInterval(() => {
      if (scrolling) {
        scrolling = false;
        scrollHandler();
      }
    }, 250);
  },
  _hashcheck() {
    // check for URL hash, to override the plugin option for a default starting tab
    let { hash } = document.location;
    const plugin = this;

    if (hash) {
      // if the hash provided matches a tab - set it as active
      hash = plugin.tabLinks.filter(`a[href='${hash}']`).parent().index() + 1;
      plugin.activeTab = (hash >= 1) ? hash : plugin.activeTab;

      window.setTimeout(() => {
        // scroll to tab if there is a hash in the url:
        if (hash >= 1) {
          plugin._scrollToItself();
        }
      }, 250);
    }
  },
  _historyEdit(newHash) {
    if (typeof newHash === 'undefined' || newHash === null) {
      // eslint-disable-next-line
      newHash = '';
    }

    if (newHash.length < 1) {
      if (window.history.pushState) {
        window.history.pushState('', document.title, ' ');
      }
      else {
        window.location.hash = '';
      }
      return;
    }

    // eslint-disable-next-line
    newHash = new RegExp('#').test(newHash) ? newHash : `#${newHash}`;
    if (window.history.replaceState) {
      window.history.replaceState('', document.title, newHash);
    }
    else {
      window.location.replace(newHash);
    }
  },
  _calcPosition() {
    const tabsPosition = (this.innerWrap.closest('.Section').length > 0) ? this.innerWrap.closest('.Section') : this.innerWrap;
    const
      tabsTop = tabsPosition.offset().top;
    return [tabsTop, tabsTop + tabsPosition.height()];
  },
  _scrollToItself() {
    const tabsData = this._calcPosition();
    const tabsPosition = tabsData[0];
    $('html,body').animate({ scrollTop: tabsPosition }, 400);
  },
  _touchstart(e) {
    this.scrolling = undefined;
    this.deltaX = 0;
    this.startX = e.touches[0].pageX;
    this.startY = e.touches[0].pageY;
    this.stripeWidth = this.innerWrap[0].scrollWidth;
    e.stopPropagation();
  },
  _touchend() {
    if (this.scrolling) return;

    if (Math.abs(this.deltaX) > this.stripeWidth / 100) {
      if (this.deltaX < 0) {
        this._showPrevTab();
      } else if (this.deltaX > 0) {
        this._showNextTab();
      } else {
        this._resetPosition();
      }
    } else {
      this._resetPosition();
    }

    this.deltaX = null;
    this.startX = null;
    this.startY = null;
  },
  _touchmove(e) {
    const changeX = this.startX - e.touches[0].pageX;
    const changeY = e.touches[0].pageY - this.startY;

    // if pinch abort
    if (e.touches.length > 1 || (e.scale && e.scale !== 1)) return;

    this.deltaX = (changeX / this.stripeWidth) * 100;

    if (typeof this.scrolling === 'undefined') {
      this.scrolling = Math.abs(changeX) < Math.abs(changeY);
    }

    if (this.scrolling) return;
    this._translateX(this.deltaX);

    e.preventDefault();
  },
  show(e, eq) {
    this.tabLinks.removeClass('is-active').removeAttr('aria-label');

    const { options } = this;
    const tabClicked = (e) ? $(e.target).addClass('is-active').attr('aria-label', 'selected') : this.tabLinks.eq(eq).addClass('is-active').attr('aria-label', 'selected');
    const tabIndex = eq || this.tabLinks.index(tabClicked);
    if (e) {
      // this is a click event
      if (options.preventDefault) {
        e.preventDefault();
      }
      if (options.stopPropagation) {
        e.stopPropagation();
      }

      if (options.scrollToTabs) {
        this._scrollToItself();
      }
      if (options.setAnchors) {
        this._historyEdit(tabClicked.attr('href'));
      }
    }

    this.tabs.removeClass('is-active').eq(tabIndex).addClass('is-active');

    if (options.animateHeight) {
      window.setTimeout(() => {
        this.innerWrap.animate({ height: this.tabs.filter('.is-active').height() }, 350);
      }, 4);
    }
    this._resetPosition();
  } // end of show
};

$.fn[pluginName] = function tabsPlugin(options) {
  return this.each(function each() {
    if (!$.data(this, `plugin_${pluginName}`)) {
      $.data(this, `plugin_${pluginName}`, new Plugin(this, options));
    }
  });
};
