import $ from 'jquery';

/* eslint-disable */
$.support.cssProperty = (function supportCssPropertyFactory() {
  return function supportCssProperty(a, e) {
    const d = (document.body || document.documentElement).style;
    let c;

    if (typeof d === 'undefined') {
      return !1;
    }

    if (typeof d[a] === 'string') {
      return e ? a : !0;
    }

    const b = 'Moz Webkit Khtml O ms Icab'.split(' ');

    for (a = a.charAt(0).toUpperCase() + a.substr(1), c = 0; c < b.length; c++) {
      if (typeof d[b[c] + a] === 'string') {
        return e ? b[c] + a : !0;
      }
    }
  };
})();
/* eslint-enable */
