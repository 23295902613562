(() => {
  const customPaddingClass = 'has-custom-padding';

  function findCousin(elements, nextOrPrev, selector) {
    // Reference the last of the selected elements
    const reference = elements[elements.length - 1];

    // Set of all elements matching the selector
    const set = Array.from(document.querySelectorAll(selector));

    // Finds the position of the reference element in the set of all elements
    const pos = set.indexOf(reference);

    // If the element does not match the selector for cousin elements return null
    if (set.length === (pos - 1)) return null;

    // Return the next or previous cousin to the reference
    return set[pos + (nextOrPrev === 'next' ? 1 : (-1))];
  }

  // do not execute in edit mode
  const url = window.location.href.toLowerCase();

  if (url.indexOf('/action/edit') > 0 || url.indexOf('/action/inedit') > 0) {
    return;
  }

  let resizing = false;

  function calculateOffsets() {
    document.querySelectorAll('.Section--patch').forEach(patch => {
      const patchHalfHeight = `${Math.ceil(patch.offsetHeight / 2)}px`;

      if (document.body.offsetWidth > 1460) {
        const containers = patch.querySelectorAll('.container');
        const prevCousin = findCousin(containers, 'prev', '.Section .container, .IP-container, .WUG-container');
        const nextCousin = findCousin(containers, 'next', '.Section .container, .IP-container, .WUG-container');

        patch.classList.add('is-active');

        patch.setAttribute('style', `margin-top: -${patchHalfHeight}; margin-bottom: -${patchHalfHeight}`);

        if (prevCousin) {
          prevCousin.classList.add(customPaddingClass);
          prevCousin.style.paddingBottom = patchHalfHeight;
        }

        if (nextCousin) {
          nextCousin.classList.add(customPaddingClass);
          nextCousin.style.paddingTop = patchHalfHeight;
        }
      }
    });
  }

  if (!document.querySelector('.sfPageEditor') && document.querySelector('.Section--patch')) {
    window.addEventListener('resize', () => {
      resizing = true;
    });

    window.setInterval(() => {
      if (resizing) {
        calculateOffsets();
        resizing = false;
      }
    }, 500);

    calculateOffsets();
  }
})();
