import $ from 'jquery';

$(() => {
  const $stickyNav = $('#js-wug-nav');
  const $contextualNav = $('.ContextualNav')
  const $page = $('html, body');
  const isFAQ = $('.Accordion').hasClass('Accordion--FAQ');
  const toggles = $('.Accordion-toggle');
  const contents = $('.Accordion-inner');


  contents.each((i, el) => {
    $(el).not(':visible').attr('aria-hidden', 'true');
  });
  toggles.each((i, el) => {
    if ( $(el).hasClass('is-active') || $(el).parent().hasClass('is-active') ) {
      $(el).attr({
        'aria-expanded': 'true',
        'aria-label': 'Expanded'
      });
    }
    else {
      $(el).attr({
        'aria-expanded': 'false',
        'aria-label': 'Collapsed'
      });
    }
  });

  toggles.on('click', function onClick(e) {
    e.preventDefault();

    const $this = $(this);
    const $parent = $this.parent();
    const $switchContent = $this.closest('.Accordion-wrap').find('.Accordion-switch');

    // FAQs scroll to item
    // this behavior is not needed on Resources page
    if ( isFAQ ) {
      if ( !$parent.hasClass('is-active') ) {
        $parent
          .addClass('is-active')
          .siblings()
          .removeClass('is-active')
          .find('.Accordion-inner')
          .stop()
          .slideUp('fast');

        $parent.siblings().find('.Accordion-toggle').attr({
          'aria-expanded': 'false',
          'aria-label': 'Collapsed'
        });
        $this.attr({
          'aria-expanded': 'true',
          'aria-label': 'Expanded'
        });

        $parent.siblings().find('.Accordion-inner').attr('aria-hidden', 'true');
        $this.next().attr('aria-hidden', 'false');

      } else {
        $parent.removeClass('is-active');
        $this.attr({
          'aria-expanded': 'false',
          'aria-label': 'Collapsed'
        });
        $this.next().attr('aria-hidden', 'true');
      }
      $this.next().slideToggle('fast');

      const stickyNavHeight = ($stickyNav.height()) ? ($stickyNav.height()) : 0;
      const contextualNavHeight = ($contextualNav.height()) ? ($contextualNav.height()) : 0;
      const scrollPosition = $parent.offset().top - stickyNavHeight - contextualNavHeight;

      $page.not(':animated').stop().animate({
          scrollTop: scrollPosition
      }, 350);
    }
    // default behavior, set on Resources page
    else {
      $parent
      .toggleClass('is-active')
      .siblings()
      .removeClass('is-active')
      .find('.Accordion-inner')
      .stop()
      .slideUp(300);

      $this.next().slideToggle(300);

      $switchContent.removeClass('is-active').eq($this.parent().index()).toggleClass('is-active');
    }

  });
});
